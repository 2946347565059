import $ from 'jquery';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import { toggleAutoInit } from 'tiny-ui-toggle';

import(/* webpackChunkName: "parsley" */ 'parsleyjs');

import(
    /* webpackChunkName: "spotlight" */
    /* webpackPrefetch: true */
    './spotlight').then((spotlight) => {
        spotlight.initSpotlightSlider();
    });

const site = {
    init: function(){

        /**
         * Make a block with link fully clickable
         */
        $('.js-click').on('click', function() {
            const link = $(this).find("a");
            if ( link.attr('target') === '_blank') {
                window.open(link.attr("href"));
            }
            else {
                window.location=link.attr("href");
            }
            return false;
        });

        $('.js-show-menu').on('click', function(e) {
            e.preventDefault();
            $('.nav--primary').toggle();
        });
    }
};

$(function() {
    site.init();
    toggleAutoInit();
});
